#app-notfound {
  position: relative;
  height: 60vh;
}

#app-notfound .app-notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.app-notfound .app-notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url('./assets/emoji.png');
  background-size: cover;
}

.app-notfound .app-notfound-404:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #faf9f9;
  z-index: -1;
}

.app-notfound h1.app-heading {
  font-family: 'Nunito', sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #ef7e2d;
  text-transform: uppercase;
}

.app-notfound h2.app-heading {
  font-family: 'Nunito', sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.app-notfound h1.app-heading:after,
.app-notfound h1.app-heading:before,
.app-notfound h2.app-heading:after,
.app-notfound h2.app-heading:before {
  display: none;
}

.app-notfound p {
  font-family: 'Nunito', sans-serif;
  color: #998c7d;
  font-weight: 400;
}

.app-notfound a {
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}

@media only screen and (max-width: 767px) {
  .app-notfound .app-notfound-404 {
    width: 110px;
    height: 110px;
  }
  .app-notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}

@media only screen and (max-height: 600px) {
  #app-notfound {
    height: 100vh;
  }
}
p{
    margin-top:0;
    margin-bottom:0;
}