@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html,
body,
#root,
#root > div {
  margin: 0;
  font-family: 'Roboto', 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.das-bold {
  font-weight: bold;
}

.das-primary-bg {
  background-color: rgb(254, 145, 67) !important;
}
.das-primary {
  color: rgb(254, 145, 67) !important;
}
.das-light-bg {
  background-color: rgb(255, 248, 228) !important;
}
.das-primary-bg-lightest {
  background-color: rgb(255, 213, 183);
}
.das-border-bottom-active {
  border-bottom: 2px solid rgb(254, 145, 67);
}
.das-border-bottom-hover {
  border-bottom: 2px solid #343a40;
}
.das-hover-primary:hover {
  background-color: rgb(255, 213, 183);
}
.das-hover:hover {
  background-color: #eae6e3;
}
.das-account:hover {
  background-color: #eae6e3;
}
.das-account-dropdown {
  min-width: 175px;
}

.das-avatar {
  width: 40px;
  height: 40px;
  margin: 0px 0.5rem 0px 0px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid rgb(255, 180, 127);
  border-image: initial;
  background-color: rgb(254, 145, 67) !important;
  padding: 4px 6px;
}

.das-avatar span {
  font-size: large;
  font-weight: 500;
  width: auto;
  color: #fff;
}

.das-queue-item {
  font-size: 14px;
  outline: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 3px;
  margin: 0px 10px;
  padding: 8px 4px 8px 12px;
  transition: background-color 0.15s ease-in-out 0s;
}

.das-queue-item-count {
  font-size: 14px;

  outline: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 3px;
  margin: 0px 0px 0px 10px;
  padding: 4px 8px;
}

.das-disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}

.das-textarea {
  border: 1px solid transparent;
  display: block;
  font-size: 16.5px;
  padding-top: 12px;
  padding-left: 16px;
  width: 100%;
  height: 225px;
  resize: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  outline: none;
}

.das-textarea-nav {
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 16px 0px;
  border-top: 1px solid rgb(234, 230, 227);
}

.das-textarea-button {
  height: 36px;
  background-color: rgb(234, 230, 227);
  color: rgb(144, 141, 138);
  margin-top: 8px;
  margin-left: 8px;
  text-align: left;
  position: relative;
  border-radius: 4px;
  padding: 7px 12px;
}

.das-textarea-icon:hover {
  color: #1d2124;
}

.das-dot {
  height: 12px;
  width: 12px;
  border-radius: 10px;
}

.das-link:hover {
  color: rgb(239, 126, 45);
}

.das-react-select {
  background-color: #ef7e2d;
}

.das-react-select__control {
  background-color: rgb(254, 145, 67) !important;
  color: #ffffff !important;
}

.das-react-select__placeholder,
.das-react-select__dropdown-indicator {
  color: #ffffff !important;
}

.das-react-select__indicator-separator {
  background: #ffffff !important;
}

.das-react-select__control--is-focused {
  box-shadow: 0 0 0 3px #ffb47f !important;
  border-color: #ef7e2d !important;
}

.das-border {
  border: 1px solid rgb(254, 145, 67) !important;
}

.das-scroll-style-no-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.das-scroll-style-no-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.das-scroll-style::-webkit-scrollbar-track {
  opacity: 0.5;
  background-color: #f9f9f9;
}

.das-scroll-style::-webkit-scrollbar {
  width: 8px;
}

.das-scroll-style::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #fe9143;
}

.das-scroll-style-dark::-webkit-scrollbar {
  width: 8px;
}

.das-scroll-style-dark::-webkit-scrollbar-track {
  background-color: #454545;
}

.das-scroll-style-dark::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #fe9143;
}

.das-splitter-container {
  overflow-y: scroll !important;
  height: calc(100vh - 117px) !important;
}

.das-splitter-container .layout-splitter {
  height: auto !important;
}

.das-splitter-container-scroll {
  overflow-y: scroll !important;
  height: auto !important;
}

.das-splitter {
  height: calc(100vh - 117px) !important;
}

.das-splitter .layout-splitter {
  width: 2px !important;
  opacity: 0.5;
}

.das-splitter .layout-splitter:hover {
  width: 5px !important;
  opacity: 1;
}

.das-splitter-dark .layout-splitter {
  background-color: #454545 !important;
}

.das-splitter .layout-pane::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.das-manager-grid {
  max-height: calc(100vh - 390px);
}
.das-account-grid {
  max-height: calc(100vh - 230px);
}
.das-texteditor .ql-toolbar {
  background-color: #f68c23 !important;
}

.das-texteditor span,
.ql-stroke {
  color: #fff !important;
  stroke: #fff !important;
}

.das-texteditor .ql-fill {
  stroke: none !important;
  fill: #fff !important;
}

/*--------------------------------------------------------*/

.background-color-white {
  background-color: #fff;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.hand-cursor {
  cursor: pointer;
}

.flex-scroll-container {
  display: flex;
  /* flex-flow: row wrap;*/
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scroll-y {
  overflow-y: scroll;
}

.content {
  flex: 1;
  display: flex;
  overflow: auto;
}

.box {
  min-height: min-content; /* needs vendor prefixes */
  display: flex;
}
.star-dot {
  height: 6px;
  width: 6px;
  background-color: rgb(253, 211, 64);
  display: inline-block;
  border-radius: 50%;
  margin: 10px 11px;
}

.body-text {
  font-size: 12px;
}

.disputed-bg {
  background-color: #ec6060;
}
.escalated-bg {
  background-color: #fdd340;
}
.do-not-respond-bg,
removed-at-review-site-bg,
ignored-bg,
posted-bg {
  background-color: #1adf75;
}
.indraft-bg,
proposed-bg {
  background-color: #36bcf6;
}
.ready-bg {
  background-color: #32afe5;
}
.undrafted-bg {
  background-color: #ef7e2d;
}
.closed-bg {
  background-color: #17c768;
}
.flag-bg {
  background-color: #ffdfdf;
}
.not-active-bg {
  background-color: #eae6e3;
}

.legal {
  font-size: 8px;
}
.disclaimer,
body-text {
  font-size: 12px;
}
.small-text {
  font-size: 10px;
}
.small-header {
  font-size: 14px;
}
.medium-header {
  font-size: 16px;
}
.header {
  font-size: 18px;
}
.large-header {
  font-size: 22px;
}
.xl-header {
  font-size: 24px;
}

.escalate-btn {
  background-color: rgb(255, 224, 114) !important;
}
.escalate-btn:hover {
  background-color: #ffd300 !important;
}

.btn-height {
  height: 40px !important;
}

.checkbox {
  display: flex;
  width: 21px;
  height: 21px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: white;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  background: white;
  margin: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(254, 145, 67);
}

.circle {
  border: 4px solid rgb(255, 148, 148);
  background-color: #ffffff;
  height: 125px;
  border-radius: 50%;
  width: 125px;
}

.circle-success {
  border: 4px solid rgb(26, 223, 117);
  background-color: #ffffff;
  height: 125px;
  border-radius: 50%;
  width: 125px;
}

.error-bg {
  background-color: rgb(255, 148, 148);
}

.error {
  color: rgb(255, 148, 148);
}
.success {
  color: rgb(26, 223, 117);
}

.underline {
  text-decoration: underline;
}

.tab {
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 5px;
  margin: 0px 4px;
}

.tab:hover {
  border-bottom: 2px solid black;
}

.tab-active {
  border-bottom: 2px solid rgb(254, 145, 67) !important;
}

.pagination {
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
}

.break-me {
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(254, 145, 67);
}

.page {
  padding: 3px 7px;
  border-radius: 2px;
  margin-right: 4px;
  color: rgb(254, 145, 67) !important;
}

.active-page {
  background-color: rgb(254, 145, 67) !important;
  color: white !important;
  padding: 3px 7px;
  border-radius: 2px;
  margin-right: 4px;
}

*:focus {
  outline: none;
}

.margin-t-8 {
  margin-top: 8px;
}

.email-template-button {
  min-width: 50px;
}

.date-picker {
  width: 300px;
}

.filter-search {
  background-color: rgb(248, 248, 248);
  width: 100%;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 10px;
  border-radius: 3px;
  outline: none;
}

.comments-input {
  width: 80%;
  white-space: normal;
  background-color: rgb(248, 248, 248);
  font-size: 14px;
  min-height: 20px;
  resize: vertical;
  width: 100%;
  border-radius: 4px;
  border-width: 0px;
  margin: 15px 0px;
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 10px;
}

.account-search {
  font-size: 14px;
  width: 100%;
  background-color: rgb(233, 233, 233);
  outline: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 4px;
  margin: 0px;
  padding: 8px 30px;
}

.account-search-container {
  box-shadow: rgba(57, 58, 60, 0.1) 0px 0px 10px;
  max-width: 1021px;
  min-width: 750px;
  padding: 12px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin: 0px 0px 25px;
}

.sidebar-primary {
  border-left: 2px solid rgb(254, 145, 67);
}

.sidebar-hover {
  border-left: 2px solid rgb(0, 0, 0);
}
